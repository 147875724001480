import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-detalle-orden',
  templateUrl: './detalle-orden.page.html',
  styleUrls: ['./detalle-orden.page.scss'],
})
export class DetalleOrdenPage implements OnInit {
  orden;
  pedido = [];
  datosEntrega;
  nombreLocal = '';
  wp;
  ordenId;
  comprobarTrans: boolean;
  constructor(private navParams: NavParams) { }
  ngOnInit() {
    this.comprobarTrans = false;
    //let n = localStorage.getItem('adminLocal');
    //let p = JSON.parse(n);
    //this.nombreLocal = p.nombreLocal;
    const item = this.navParams.get('orden');
    this.orden = item;
    this.ordenId = this.orden.objectId;
    //this.datosEntrega = JSON.parse(item.DatosEntrega);
    //this.pedido = JSON.parse(item.Pedido);
    //this.wp = this.datosEntrega.telefono;
    console.log(this.orden);
    //console.log(this.datosEntrega);
    //console.log(this.pedido);
  }

}
