import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

@Directive({
  selector: '[appParallaxHeader]'
})
export class ParallaxHeaderDirective {
  headerAntesala: any;
  headerAntesalaHeight: number;
  moveAntesalaImage: number;
  scaleAntesalaImage: number;
  header: any;
  headerHeight: number;
  moveImage: number;
  scaleImage: number;
  header2: any;
  headerHeight2: number;
  moveImage2: number;
  scaleImage2: number;
  bgImg: any;
  bgImgHeight: number;
  moveImage3: number;
  scaleImage3: number;
  bgImg2: any;
  bgImg3: any;

  constructor(public element: ElementRef, public renderer: Renderer2,
    private domCtrl: DomController) { }

  ngOnInit() {
    let content = this.element.nativeElement;
    this.headerAntesala = content.getElementsByClassName('parallax-image')[0];
    this.header = content.getElementsByClassName('parallax-image')[0];
    this.header2 = content.getElementsByClassName('parallax-image2')[0];
    this.bgImg = content.getElementsByClassName('parallax-image3')[0];
    //this.bgImg2 = content.getElementsByClassName('parallax-image4')[0];
    //this.bgImg3 = content.getElementsByClassName('parallax-image5')[0];

    this.domCtrl.read(() => {
      if (this.headerAntesala) {
        this.headerAntesalaHeight = this.headerAntesala.clientHeight;
      }//console.log('HEIGHT_: ', this.headerHeight)
    });
    this.domCtrl.read(() => {
      if (this.header) {
        this.headerHeight = this.header.clientHeight;
      }//console.log('HEIGHT_: ', this.headerHeight)
    });
    this.domCtrl.read(() => {
      if (this.header2) {
        this.headerHeight2 = this.header2.clientHeight;
      }//console.log('HEIGHT_: ', this.headerHeight)
    });
    this.domCtrl.read(() => {
      if (this.bgImg) {
        this.bgImgHeight = this.bgImg.clientHeight;
      }//console.log('HEIGHT_: ', this.headerHeight)
    });
  }


  @HostListener('ionScroll', ['$event']) onContentScroll($event) {
    //console.log('EVENT_: ', $event);
    const scrollTop = $event.detail.scrollTop;
    //console.log('scroll_: ', scrollTop);

    if (this.headerAntesala) {
      this.domCtrl.write(() => {
        if (scrollTop > 0) {
          this.moveAntesalaImage = scrollTop / 2;
          this.scaleAntesalaImage = 1;
        } else {
          this.moveAntesalaImage = scrollTop / 1.4;
          this.scaleAntesalaImage = -scrollTop / this.headerAntesalaHeight + 1;
        }
        this.renderer.setStyle(this.headerAntesala, 'webkitTransform',
          'translate3d(0,' + this.moveAntesalaImage + 'px,0) scale(' + this.scaleAntesalaImage + ',' + this.scaleAntesalaImage + ')'
        );
      });
    }

    if (this.header) {
      this.domCtrl.write(() => {
        if (scrollTop > 0) {
          this.moveImage = scrollTop / 2;
          this.scaleImage = 1;
        } else {
          this.moveImage = scrollTop / 1.4;
          this.scaleImage = -scrollTop / this.headerHeight + 1;
        }
        this.renderer.setStyle(this.header, 'webkitTransform',
          'translate3d(0,' + this.moveImage + 'px,0) scale(' + this.scaleImage + ')'
        );
      });
    }

    if (this.header2) {
      this.domCtrl.write(() => {
        if (scrollTop > 0) {
          this.moveImage2 = scrollTop / 2;
          this.scaleImage2 = 1;
        } else {
          this.moveImage2 = scrollTop / 1.4;
          this.scaleImage2 = -scrollTop / this.headerHeight2 + 1;
        }
        this.renderer.setStyle(this.header2, 'webkitTransform',
          'translate3d(0,' + this.moveImage2 + 'px,0) scale(' + this.scaleImage2 + ')'
        );
      });
    }

    if (this.bgImg) {
      this.domCtrl.write(() => {
        if (scrollTop > 0) {
          this.moveImage3 = scrollTop / 2;
          this.scaleImage3 = 1;
        } else {
          this.moveImage3 = scrollTop / 1.4;
          this.scaleImage3 = -scrollTop / this.bgImgHeight + 1;
        }
        this.renderer.setStyle(this.bgImg, 'webkitTransform',
          'translate3d(0,' + this.moveImage3 + 'px,0) scale(' + this.scaleImage3 + ')'
        );
      });
    }
  }

}


